var Mustache = Mustache || {};
var generic = generic || {};

(function ($) {
  Drupal.behaviors.discover_more_module_v1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var $thismodule = $('.js-discover-more__module', context);

      $thismodule.each(function () {
        var $module = $(this);
        var $moduleBlock = $module.find('.js-discover-block');
        var $articleWrapper = $module.closest('.js-discover-article');
        var $leftChevron = $moduleBlock.attr('data-arrow-left');
        var $leftChevronAltText = $moduleBlock.attr('data-arrow-left-alt');
        var $rightChevron = $moduleBlock.attr('data-arrow-right');
        var $rightChevronAltText = $moduleBlock.attr('data-arrow-right-alt');
        var $carouselInner;
        var $firstSlideClone, $secondSlideClone, $lastSlideClone, $secondLastSlideClone, $leftChevronElem, $rightChevronElem;
        var $olapicViewContainer = $module.find('.js-discover-olapic-viewer-container');
        var $currentOlapicTile;
        var $productList;
        var $leftNav;
        var $rightNav;
        var blockHtml = '';
        var partialHtml = '';
        var $carouselWrapper;
        var $carouselSlides;
        var $carouselAllSlides; // "real" and clone slides
        var olapicViewer;
        var olapicApiKey = '';
        var displayAsCarousel = $module.data('format') === 'carousel';
        var carouselMoveIndex = 2;
        var olapicViewCount = 0;
        var olapicViewCurrentIndex = 0;

        // Handle the arrow images.
        function createArrows() {
          // Create our navigation elements.
          $('<div role="img" aria-label="Previous" class="discover-carousel__nav js-discover-carousel__nav discover-carousel__nav--left js-discover-carousel__nav--left"><img class="lazyload"/></div>').insertBefore($moduleBlock);
          $('<div role="img" aria-label="Next" class="discover-carousel__nav js-discover-carousel__nav discover-carousel__nav--right js-discover-carousel__nav--right"><img class="lazyload"/></div>').insertAfter($moduleBlock);

          // Assign them.
          $leftChevronElem = $('.js-discover-carousel__nav--left');
          $rightChevronElem = $('.js-discover-carousel__nav--right');

          // Add the image sources and alt text.
          if ($leftChevron) {
            $leftChevronElem.find('img').attr('data-src', $leftChevron);
            $leftChevronElem.find('img').attr('alt', $leftChevronAltText);
            $leftChevronElem.css({
              'background-image': 'none',
              'transform': 'none'
            });
          }
          if ($rightChevron) {
            $rightChevronElem.find('img').attr('data-src', $rightChevron);
            $rightChevronElem.find('img').attr('alt', $rightChevronAltText);
            $rightChevronElem.css({
              'background-image': 'none',
              'transform': 'none'
            });
          }
        }

        // Clone the first and last slide block for infinite scrolling.
        function cloneSlides() {
          var $firstSlide = $('.js-discover-carousel__inner').find('.js-discover-carousel__slide').first();
          var $lastSlide = $('.js-discover-carousel__inner').find('.js-discover-carousel__slide').last();

          $firstSlideClone = $firstSlide.clone();
          $secondSlideClone = $firstSlide.next().clone();
          $lastSlideClone = $lastSlide.clone();
          $secondLastSlideClone = $lastSlide.prev().clone();

          $firstSlideClone.addClass('discover-carousel__clone js-discover-carousel__clone');
          $secondSlideClone.addClass('discover-carousel__clone js-discover-carousel__clone');
          $secondLastSlideClone.addClass('discover-carousel__clone js-discover-carousel__clone');
          $lastSlideClone.addClass('discover-carousel__clone js-discover-carousel__clone');

          // Append the cloned slides.
          $secondLastSlideClone.insertBefore($('.js-discover-carousel__inner'));
          $lastSlideClone.insertBefore($('.js-discover-carousel__inner'));
          $secondSlideClone.insertAfter($('.js-discover-carousel__inner'));
          $firstSlideClone.insertAfter($('.js-discover-carousel__inner'));
          // store ref to all slides
          $carouselAllSlides = $moduleBlock.find('.js-discover-carousel__slide');
          createArrows();
        }

        // set widths for slides and containers. For responsiveness.
        function applySlideWidths() {
          $carouselSlides = $moduleBlock.find('.js-discover-carousel__slide:not(.js-discover-carousel__clone)');
          var totalWidth = String(($carouselSlides.length + 4) * 100) + '%'; // 4 clones. 2 at the beginning and 2 at the end.

          $carouselWrapper = $moduleBlock.find('.js-discover-carousel__wrapper');
          $carouselWrapper.width(totalWidth);
          $carouselWrapper.css('margin-left', '-200%'); // move passed the first two clone slides
        }

        // Grab the 9 tiles and wrap in slides.
        function wrapTiles() {
          $moduleBlock.children().wrapAll('<div class="discover-carousel__wrapper js-discover-carousel__wrapper"><div class="discover-carousel__inner js-discover-carousel__inner"></div></div>');
          var $carouselTile = $moduleBlock.find('.js-discover-block__container');

          $carouselTile.slice(0, 3).wrapAll('<div class="discover-carousel__slide js-discover-carousel__slide"></div>');
          $carouselTile.slice(3, 6).wrapAll('<div class="discover-carousel__slide js-discover-carousel__slide"></div>');
          $carouselTile.slice(6, 9).wrapAll('<div class="discover-carousel__slide js-discover-carousel__slide"></div>');
          $carouselInner = $thismodule.find('.js-discover-carousel__inner');
          // clone slides for "infinity" carousel effect
          cloneSlides();
          // setting widths for animation
          applySlideWidths();
          // custom arrows and listeners
          setArrowListeners();
          // Adjust carousel widths for screen size.
          setResponsiveSlideWidth();
        }

        /* Set new position of the carousel */
        function updateCarouselPosition() {
          var newPosition = carouselMoveIndex * 100;

          $carouselWrapper.css('margin-left', '-' + newPosition + '%');
          setTimeout(function () {
            $carouselWrapper.removeClass('discover-carousel__wrapper--stalled');
          }, 310);
        }

        /* All animation functionality for the carousel */
        function moveCarousel() {
          updateCarouselPosition();
          if (carouselMoveIndex > ($carouselSlides.length + 1)) {
            // animation ends
            setTimeout(function () {
              carouselMoveIndex = 2;
              $carouselWrapper.addClass('discover-carousel__wrapper--stalled');
              updateCarouselPosition();
            }, 320);
          }
          if (carouselMoveIndex === 1) {
            // animation ends
            setTimeout(function () {
              carouselMoveIndex = $carouselSlides.length + 1;
              $carouselWrapper.addClass('discover-carousel__wrapper--stalled');
              updateCarouselPosition();
            }, 320);
          }
        }

        // left click handler
        function leftChevronElemClicked() {
          carouselMoveIndex--;
          moveCarousel();
        }

        // right click handler
        function rightChevronElemClicked() {
          carouselMoveIndex++;
          moveCarousel();
        }

        // Left/right Chevron listeners
        function setArrowListeners() {
          $leftChevronElem.click(function () {
            leftChevronElemClicked();
          });
          $rightChevronElem.click(function () {
            rightChevronElemClicked();
          });
        }

        // Format the grid as a carousel.
        function displayCarousel() {
          wrapTiles();
        }

        // If the window becomes smaller than the carousel's natural width, then set its widht to the windows.
        function setResponsiveSlideWidth() {
          if ($carouselAllSlides && $carouselAllSlides.length > 0) {
            var windowWidth = $(window).width();

            windowWidth <= 1124 ? $carouselAllSlides.addClass('responsive') : $carouselAllSlides.removeClass('responsive');
          }
        }

        /* Loop through the json data and separate not the nodes into the approriate block */
        function getNextIteration(json_data) {
          var blockDiscoverMore = [];

          for (var i = 0; i < json_data.length; i++) {
            if (json_data[i]) {
              blockDiscoverMore.push(json_data[i]['template_data'][0]['blogarticlemetadata']['blog_article_metadata']);
              blockDiscoverMore[i]['html_return_video_content'] = json_data[i]['template_data'][0]['videocontent']['video_content'];
            }
          }

          var bindings = {
            blog: {
              metadata: {
                blockDiscoverMore: blockDiscoverMore
              }
            }
          };

          return bindings;
        }

        /* No more than 9 tiles are required */
        function trimTiles(json_data) {
          var trimmedTiles = [];

          for (var i = 0; i < 9; i++) {
            if (json_data[i]) {
              trimmedTiles.push(json_data[i]);
            }
          }

          return trimmedTiles;
        }

        /* Dynaically generated video tiles will need to have their event listeners set */
        function applyVideoControls() {
          var $videoElem = $moduleBlock.find('.video_content_v1'),
            $playElems = $videoElem.find('.video_content_cta, .video_content_cta_link__underline');

          if ($playElems && $playElems.length > 0) {
            $.event.trigger({
              type: 'video_content_v1:apply_listeners',
              payload: $playElems,
              videoEl: $videoElem
            });
          }
          // Remove the Discover More instance of the YouTube player if there is already and instance of the player on the page.
          var $youTubePlayers = $('.js-ytPlayer');

          if ($youTubePlayers.length > 1) {
            $module.find('.js-ytPlayer').remove();
          }

          var $youTubers = $moduleBlock.find('.js-ytPlayer__icon, .js-ytPlayer__copy');

          if ($youTubers && $youTubers.length > 1) {
            $.event.trigger({
              type: 'yt_dynamic_player:apply_listeners',
              payload: $youTubers
            });
          }
        }

        /* If specific nodes have been selected for Discover More, replace the array element with them */
        function checkAlternates(json_data, discoverMoreTiles) {
          var $alternates = $module.find('.js-discover-more__alternate');

          $.each($alternates, function (alt_index, alt_value) {
            var altNid = $(alt_value).data('alt-nid');

            for (var i = 0; i < json_data.length; i++) {
              var dataNid = json_data[i]['template_data'][0]['blogarticlemetadata']['blog_article_metadata']['nid'];

              if (Number(altNid) === dataNid) {
                discoverMoreTiles.splice(alt_index, 1, json_data[i]);
              }
            }
          });

          return discoverMoreTiles;
        }

        function containsTitle(array, name) {
          var contains = false;

          $.each(array, function (arr_index, arr_value) {
            if (arr_value['product_name'] === name) {
              contains = true;
            }
          });

          return contains;
        }

        function setEmptyDefaults() {
          return [
            {
              product_url: '',
              product_name: '',
              product_image: ''
            }
          ];
        }

        function getOlapicSocials(olapicMediaLocal, facebookAppID, index) {
          var media = olapicMediaLocal[index];
          var caption = encodeURIComponent(media['caption']);
          var deeplinkUrl = window.location.href.split('#')[0];

          return {
            facebook_url: 'https://www.facebook.com/dialog/share?app_id=' + facebookAppID + ' &display=popup&href=' + deeplinkUrl,
            twitter_url: 'http://twitter.com/intent/tweet?text=' + caption,
            pinterest_url: 'https://www.pinterest.com/pin/create/button/?url=' + deeplinkUrl + '&media=' + getOlapicMediaProducts(olapicMediaLocal[0])[0]['product_image']
          };
        }

        function returnformattedProduct(prod_value) {
          var prod = {};

          prod_value['product_url'] ? prod['product_url'] = prod_value.product_url : prod['product_url'] = '';
          prod_value['name'] ? prod['product_name'] = prod_value['name'] : prod['product_name'] = '';
          prod_value['_embedded']['base_image'] ? prod['product_image'] = prod_value['_embedded']['base_image']['images']['normal'] : prod['product_image'] = '';

          return prod;
        }

        function getOlapicMediaProducts(media) {
          var products = [];

          $.each(media['_embedded']['streams:all']['_embedded'].stream, function (prod_index, prod_value) {
            if (prod_value && prod_value.product_url !== null) {
              if (containsTitle(products, prod_value.name) === false) {
                products.push(returnformattedProduct(prod_value));
              }
            }
          });
          // Fail-safe should the Olapic API return no products.
          if (products.length === 0) {
            return setEmptyDefaults();
          }

          return products;
        }

        function getOlapicViewerData(index, olapicMediaLocal) {
          var $olapicTileData = $currentOlapicTile.find('.js-discover-block__olapicdata');

          if ($olapicTileData && $olapicTileData.length > 0) {
            var facebookAppID = $olapicTileData.data('facebook-appid');
            var olapic_one = {
              olapic_main_image: olapicMediaLocal[index].images['normal'],
              olapic_instagram_original_post: olapicMediaLocal[index].original_source,
              olapic_author_avatar: 'https://i.imgur.com/qYGRVeg.jpg',
              olapic_instagram_username: olapicMediaLocal[index]['_embedded'].uploader.name,
              olapic_photo_caption: olapicMediaLocal[index]['caption'],
              olapic_post_media_id: olapicMediaLocal[index]['id'],
              olapic_products: getOlapicMediaProducts(olapicMediaLocal[index]),
              olapic_social: getOlapicSocials(olapicMediaLocal, facebookAppID, index),
              blog_article_metadata_olapic_share: $olapicTileData.data('share-text'),
              blog_article_metadata_olapic_shop_look: $olapicTileData.data('shop-look'),
              blog_article_metadata_olapic_avatar_alt: $olapicTileData.data('avatar-alt'),
              blog_article_metadata_olapic_report_text: $olapicTileData.data('report-text'),
              blog_article_metadata_olapic_email_placeholder: $olapicTileData.data('email-placeholder'),
              blog_article_metadata_olapic_email_reason: $olapicTileData.data('email-reason'),
              blog_article_metadata_olapic_email_cancel: $olapicTileData.data('email-cancel'),
              blog_article_metadata_olapic_email_report: $olapicTileData.data('email-report'),
              blog_article_metadata_olapic_close: $olapicTileData.data('close-text'),
              blog_article_metadata_olapic_report_okay: $olapicTileData.data('report-okay'),
              blog_article_metadata_olapic_report_fail: $olapicTileData.data('report-fail'),
              blog_article_metadata_olapic_report_email_invalid: $olapicTileData.data('email-invalid')
            };

            return olapic_one;
          }

          return {};
        }

        function setNavigationListeners(productCount) {
          var navCounter = 0;

          $rightNav.once().on('click', function () {
            navCounter++;
            var dist = 170;

            if ($productList.find('li').length > 0) {
              dist = $($productList.find('li')[0]).outerWidth(true);
            }
            $productList.css('margin-left', '-' + navCounter * dist + 'px');

            if (navCounter === productCount - 1) {
              $(this).addClass('hiding');
            }
            if (navCounter > 0) {
              $leftNav.removeClass('hiding');
            }
          });

          $leftNav.once().on('click', function () {
            navCounter--;
            var dist = 170;

            if ($productList.find('li').length > 0) {
              dist = $($productList.find('li')[0]).outerWidth(true);
            }
            $productList.css('margin-left', '-' + navCounter * dist + 'px');
            if (navCounter === 0) {
              $(this).addClass('hiding');
            }
            if (navCounter < productCount - 1) {
              $rightNav.removeClass('hiding');
            }
          });
        }

        function configureProductCarousel(viewData) {
          var productCount = viewData.olapic_products.length;

          if (productCount > 2) {
            var carouselWidth = productCount * 170;

            $productList = $olapicViewContainer.find('.products-list');
            $leftNav = $olapicViewContainer.find('.products-previous');
            $rightNav = $olapicViewContainer.find('.products-next');
            $productList.width(carouselWidth);
            $rightNav.removeClass('hiding');
            setNavigationListeners(productCount);
          }
        }

        function resizeOlapicImagery() {
          var $olapicTiles = getOlapicTiles();

          $.each($olapicTiles, function (tile_index, tile_value) {
            var $olapicTile = $(tile_value),
              $currentImages = $olapicTile.find('picture'),
              $imageUsed = getImageUsed($currentImages),
              $imgtag = $imageUsed.find('img'),
              imgtagWidth = $imgtag.css('width');

            if (checkForOlapicTile($imgtag)) {
              $imgtag.siblings('.olapicimg').css('height', imgtagWidth);
            } else {
              $olapicTile.find('.js-olapicimg').css('height', imgtagWidth);
            }
          });
        }

        function checkForOlapicTile($imgtag) {
          if ($imgtag.siblings('.olapicimg').length > 0) {
            return true;
          }

          return false;
        }

        function moveOlapicViewCarouselLeft(olapicMediaLocal) {
          olapicViewCurrentIndex--;
          if (olapicViewCurrentIndex === -1) {
            olapicViewCurrentIndex = olapicViewCount - 1;
          }
          openOlapicView(olapicViewCurrentIndex, olapicMediaLocal);
        }

        function moveOlapicViewCarouselRight(olapicMediaLocal) {
          olapicViewCurrentIndex++;
          if (olapicViewCurrentIndex === olapicViewCount) {
            olapicViewCurrentIndex = 0;
          }
          openOlapicView(olapicViewCurrentIndex, olapicMediaLocal);
        }

        function resetReportElements($reportWrapper) {
          $reportWrapper.removeClass('open');
          $olapicViewContainer.find('.reportPhotoTextHide').removeClass('showing');
          $olapicViewContainer.find('.form-group.report-buttons').removeClass('hiding');
        }

        function isEmail(email) {
          var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

          return regex.test(email);
        }

        function reportImagery(viewData) {
          var reportEmail = $olapicViewContainer.find('input[name=email]').val(),
            reportReason = encodeURIComponent($olapicViewContainer.find('input[name=reason]').val()),
            validEmail = isEmail(reportEmail),
            $invalidMessage = $olapicViewContainer.find('#reportInvalidEmail');

          if (!validEmail) {
            $invalidMessage.addClass('showing');
          } else {
            $.ajax({
              url: 'https://photorankapi-a.akamaihd.net/media/' + viewData.olapic_post_media_id + '/reports?version=v2.2&auth_token=' + olapicApiKey + '&email=' + reportEmail + '&reason=' + reportReason,
              context: document.body
            }).done(function () {
              $olapicViewContainer.find('.form-group.report-buttons').addClass('hiding');
              $invalidMessage.removeClass('showing');
              $olapicViewContainer.find('#reportOK').addClass('showing');
            });
          }
        }

        function setOlapicViewerListeners(viewData, olapicMediaLocal) {
          var $reportWrapper = $olapicViewContainer.find('.olapic-report-wrapper');

          $olapicViewContainer.find('.viewer-close').once().on('click', function () {
            olapicViewCurrentIndex = 0;
            $olapicViewContainer.find('#olapicTemplatesArea').removeClass('showing');
          });
          $olapicViewContainer.find('#viewer-prev').once().on('click', function () {
            moveOlapicViewCarouselLeft(olapicMediaLocal);
          });
          $olapicViewContainer.find('#viewer-next').once().on('click', function () {
            moveOlapicViewCarouselRight(olapicMediaLocal);
          });
          $olapicViewContainer.find('.report-photo').once().on('click', function () {
            if ($reportWrapper.hasClass('open')) {
              $reportWrapper.removeClass('open');
            } else {
              $reportWrapper.addClass('open');
            }
          });
          $olapicViewContainer.find('.cancel-report').once().on('click', function () {
            resetReportElements($reportWrapper);
          });
          $olapicViewContainer.find('.close-report').once().on('click', function () {
            resetReportElements($reportWrapper);
          });
          $olapicViewContainer.find('.submit-report').once().on('click', function () {
            reportImagery(viewData);
          });
        }

        function openOlapicView(index, olapicMediaLocal) {
          if (!index) {
            index = 0; // IE workaround. Doesn't allow default parameters.
          }
          $olapicViewContainer.empty(); // Garbage collection
          var viewData = getOlapicViewerData(index, olapicMediaLocal);
          var rendered = Mustache.render(olapicViewer, viewData);

          $olapicViewContainer.html(rendered);
          configureProductCarousel(viewData);
          setOlapicViewerListeners(viewData, olapicMediaLocal);
          $olapicViewContainer.find('#olapicTemplatesArea').addClass('showing');
        }

        function processOlapicData($imageUsed, $olapicTile, olapicMediaLocal, olapicImageCountLocal) {
          var $imgtag = $imageUsed.find('img');
          var imgtagWidth = $imgtag.css('width');
          var imageurl = olapicMediaLocal[olapicImageCountLocal].images['original'];
          var styles = '';

          if (isMobile()) {
            imageurl = olapicMediaLocal[olapicImageCountLocal].images['mobile'];
            styles += 'height: ' + imgtagWidth + ';';
          }

          styles += 'background-image: url(' + imageurl + ');';
          if (checkForOlapicTile($imgtag)) {
            $imgtag.siblings('.olapicimg').css('height', imgtagWidth);
          } else {
            var $olapicdiv = $('<div class="olapicimg js-olapicimg" style="' + styles + '" data-olapic-position="' + olapicImageCountLocal + '"></div>');

            $imageUsed.append($olapicdiv).ready(function () {
              $imgtag.css('display', 'none');
              $olapicdiv.height($olapicdiv.width());
              $olapicdiv.once().on('click', function () {
                $currentOlapicTile = $olapicTile;
                olapicViewCurrentIndex = 0;
                openOlapicView(Number($(this).data('olapic-position')), olapicMediaLocal);
              });
            });
          }

          olapicImageCountLocal++;
          if (olapicImageCountLocal === olapicMediaLocal.length) {
            olapicImageCountLocal = 0;
          }
        }

        function getImageUsed($currentImages) {
          var usedImage;

          $.each($currentImages, function (img_index, img_value) {
            var $image = $(img_value);

            if ($image.css('display') !== 'none') {
              usedImage = $image;
            }
          });

          return usedImage;
        }

        function checkForOlapicImagery(olapicMediaLocal, olapicImageCountLocal, $tile) {
          var $olapicTile = $tile,
            $currentImages = $olapicTile.find('picture'),
            $imageUsed = getImageUsed($currentImages);

          $imageUsed.siblings('.discover-block__olapic--content').html(olapicMediaLocal[olapicImageCountLocal].caption);
          processOlapicData($imageUsed, $olapicTile, olapicMediaLocal, olapicImageCountLocal);
        }

        function getOlapicTiles() {
          var $parent = displayAsCarousel ? $thismodule.find('.js-discover-carousel__wrapper') : $moduleBlock;
          var olapicTiles = [],
            $tiles = $parent.find('.js-discover-block__container');

          $.each($tiles, function (tile_index, tile_value) {
            var $tile = $(tile_value);

            if ($tile.hasClass('olapic_tile')) {
              olapicTiles.push($tile);
            }
          });

          return olapicTiles;
        }

        function getOlapicData() {
          var $wrapper = displayAsCarousel ? $thismodule.find('.js-discover-carousel__wrapper') : $moduleBlock;

          $.each($wrapper.find('.olapic_tile'), function (ol_data_index, ol_data_value) {
            var $tile = $(ol_data_value),
              $dataEl = $tile.find('.js-discover-block__olapicdata'),
              streamID = $dataEl.data('stream-id'),
              apiID = $dataEl.data('api-key');

            if (streamID && apiID) {
              $.getJSON('https://photorankapi-a.akamaihd.net/streams/' + streamID + '/media/recent?rights_given=true&auth_token=' + apiID + '&version=v2.2', function (json_data) {
                var olapicMediaLocal = json_data.data['_embedded'].media;

                olapicViewCount = olapicMediaLocal.length;
                checkForOlapicImagery(olapicMediaLocal, 0, $tile);
              });
            }
          });
        }

        function getOlapicMarkup() {
          generic.template.get({
            path: '/sites/esteelauder/themes/estee_base/template_api/discover/discover_blocks_v1/discover_olapic_viewer_v1.mustache',
            forceReload: true,
            callback: function (html) {
              olapicViewer = html;
              getOlapicData();
              setResizeListener();
            }
          });
        }

        function rearrangeOlapicTilesCarousel() {
          var $wrapper = $thismodule.find('.js-discover-carousel__wrapper');

          $.each($wrapper.find('.js-discover-carousel__slide'), function (slide_index, slide_value) {
            var $slide = $(slide_value);

            $.each($slide.find('.js-discover-block__container'), function (block_index, block_value) {
              var $block = $(block_value);

              if ($block.hasClass('olapic_tile') && block_index === 0) {
                $slide.append($block);
              }
            });
          });
        }

        function olapicInit() {
          var $parent = displayAsCarousel ? $carouselInner : $moduleBlock;

          if ($parent.find('.olapic_tile').length > 0) {
            getOlapicMarkup();
            if (displayAsCarousel) {
              rearrangeOlapicTilesCarousel();
              // Re run this function should any tiles have been moved into the final incorrect position from the first run
              rearrangeOlapicTilesCarousel();
            }
          }
        }

        function isOlapic(data) {
          return data['template_data'][0]['blogarticlemetadata']['blog_article_metadata']['blog_article_metadata_tile_style'] === 'olapic_tile';
        }

        function moveOlapicPosition(arr, from, to) {
          arr.splice(to, 0, arr.splice(from, 1)[0]);
        }

        function checkOlapicIndex(index, data) {
          if (index === 0) {
            moveOlapicPosition(data, 0, 1);
          }
          if (!isMobile() && index === 5) {
            moveOlapicPosition(data, 5, 4);
          }
          if (isMobile() && index === 3) {
            moveOlapicPosition(data, 3, 4);
          }
        }

        // Rearrange the array to suit the grid layout.
        function rearrangeOlapicDataGrid(json_data) {
          for (var i = 0; i < json_data.length; i++) {
            var data = json_data[i];

            if (isOlapic(data)) {
              checkOlapicIndex(i, data);
            }
          }

          return json_data;
        }

        /* Call to API to get all nodes */
        function callAPI() {
          var apiUrl = '/elc_api/blog/all/all/9/0/';

          $.getJSON(apiUrl, function (json_data) {
            if (json_data.length > 0) {
              var discoverMoreTiles = trimTiles(json_data);

              if (!displayAsCarousel) {
                discoverMoreTiles = rearrangeOlapicDataGrid(discoverMoreTiles);
                // Run this function again to make sure any adjacent Olpaic tiles were not pushed into illegal positions
                discoverMoreTiles = rearrangeOlapicDataGrid(discoverMoreTiles);
              }

              // Check for alternative.
              discoverMoreTiles = checkAlternates(json_data, discoverMoreTiles);

              var partialObj = {
                discover_block_partial_v1: partialHtml
              };
              var bindings = getNextIteration(discoverMoreTiles);
              var rendered = Mustache.render(blockHtml, bindings, partialObj);

              $moduleBlock.html(rendered);

              // Will we format the grid as a carousel?
              if (displayAsCarousel) {
                if ($articleWrapper.length > 0) {
                  $module.addClass('carousel').detach().insertAfter($articleWrapper); // article
                } else {
                  $module.addClass('carousel'); // home page
                }
                displayCarousel();
                olapicInit();
                $module.addClass('active');
                applyVideoControls();
              } else {
                olapicInit();
                $module.addClass('active');
              }
            }
          });
        }

        /* Templates for rendering the carousel tiles dynamically */
        function getTemplates() {
          generic.template.get({
            path: '/sites/esteelauder/themes/estee_base/template_api/discover/discover_more_module_v1/discover_more_tiles_v1.mustache',
            forceReload: true,
            callback: function (html) {
              blockHtml = html;
              generic.template.get({
                path: '/sites/esteelauder/themes/estee_base/template_api/discover/discover_partials_v1/discover_block_partial_v1.mustache',
                forceReload: true,
                callback: function (partial) {
                  partialHtml = partial;
                  callAPI();
                }
              });
            }
          });
        }
        getTemplates();

        // Mobile check
        function isMobile() {
          if (Unison.fetch.now().name === 'small') {
            return true;
          }

          return false;
        }

        function setResizeListener() {
          $(window).on('resize', _.debounce(resizeOlapicImagery, 250));
        }
        $(window).on('resize', _.debounce(setResponsiveSlideWidth, 100));
      });
    }
  };
})(jQuery);
